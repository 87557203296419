<template>
  <v-container>
    <!-- ############# -->
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm4 md4 lg4 class="mb-1 text-right">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="editSupplier($route.params.id)"
          :color="$store.state.primaryColor"
        >
          {{ $t("edit") }}
          <v-icon small right>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          {{ $t("delete") }}
          <v-icon small right>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- ################ -->

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>{{ $t("do you really want to delete") }} {{ supplier.name }}?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteSupplier"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      shaped
      top
      rounded="pill"
      v-model="snackbar"
      :timeout="timeout"
      color="blue-grey"
    >
      {{ $t("deleted") }}!
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-start v-if="supplier">
        <v-flex xs12 sm12 md12 lg12>
          <span class="text-h4 font-weight-black">{{ supplier.name }}</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify="center" align="center">
        <v-flex
          class="pa-5 mx-1 elevation-2"
          style="border: 1px solid; border-radius: 5px"
        >
          <v-list dense class="elevation-1">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("supplier name") }}:
                </span>
                <span class="">{{ supplier.name }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("email") }} :
                </span>
                <span class="">{{ supplier.email }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("telephone") }} :
                </span>
                <span class="">{{ supplier.phone }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("PO BOX") }}:
                </span>
                <span class="">{{ supplier.address.pobox }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("country") }} :
                </span>
                <span class="">{{ supplier.address.country }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("county-province") }} :
                </span>
                <span class="">{{ supplier.address.county }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("city-town") }}:
                </span>
                <span class="">{{ supplier.address.city }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("district-territory") }}:
                </span>
                <span class="">{{ supplier.address.territory }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("street") }} :
                </span>
                <span class="">{{ supplier.address.street }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("estate") }} :
                </span>
                <span class="">{{ supplier.address.estate }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("building") }} :
                </span>
                <span class="">{{ supplier.address.building }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("house-suite-number") }} :
                </span>
                <span class="">{{ supplier.address.no }} </span>
              </v-col>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not get supplier details. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackErrorDelete"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{
        $t("could not delete supplier details. Try again later")
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackErrorDelete = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    supplier: "",
    pleaseWait: true,
    delDialog: false,
    snackbar: false,
    snackError: false,
    snackErrorDelete: false,
    timeout: 2000,
  }),

  created() {
    this.getSupplierProfile();
  },

  methods: {
    getSupplierProfile() {
      db.collection("suppliers")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.supplier = doc.data();

          this.pleaseWait = false;
          this.$store.commit("SET_SELECTED_SUPPLIER", this.supplier);
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
          this.snackError = true;
          this.pleaseWait = false;
        });
    },

    editSupplier(supId) {
      this.$router.push({ name: "EditSupplier", params: { id: supId } });
    },

    deleteSupplier() {
      db.collection("suppliers")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch(() => {
          this.snackErrorDelete = true;
        });
    },

    goBack() {
      this.$router.push({ name: "ViewSuppliers" });
    },
  },
};
</script>

<style></style>
